// extracted by mini-css-extract-plugin
export var relativeWrapper = "coworking-module--relativeWrapper--3RlxM";
export var decor = "coworking-module--decor--1RpkE";
export var hamburger = "coworking-module--hamburger--32iT7";
export var units = "coworking-module--units--2QLm6";
export var reactCalendarMonthViewDays = "coworking-module--react-calendar__month-view__days--22xsC";
export var reactCalendarTile = "coworking-module--react-calendar__tile--3GX9Y";
export var reactCalendarTileActive = "coworking-module--react-calendar__tile--active--2kW1V";
export var sidelines = "coworking-module--sidelines--11a_y";
export var stop = "coworking-module--stop--3dnL7";
export var bannerCarousel = "coworking-module--bannerCarousel--2VEqg";
export var nextButton = "coworking-module--nextButton--12REp";
export var backButton = "coworking-module--backButton--1brCz";
export var carousel = "coworking-module--carousel--3GiHx";
export var image = "coworking-module--image--1Gty-";
export var mobileCounter = "coworking-module--mobileCounter--1-00_";
export var inner = "coworking-module--inner--2j979";
export var container = "coworking-module--container--2_nuI";
export var sideLine = "coworking-module--sideLine--37HL-";
export var unitDetails = "coworking-module--unitDetails--1pneo";
export var detailsContainer = "coworking-module--detailsContainer--3dUi4";
export var mediaContainer = "coworking-module--mediaContainer--3zkE-";
export var hidden = "coworking-module--hidden--1WEPD";
export var controls = "coworking-module--controls--2pc5N";
export var detailsControls = "coworking-module--detailsControls--2rIvm";
export var shareContainer = "coworking-module--shareContainer--XmBAt";
export var priceContainer = "coworking-module--priceContainer--xymIi";
export var applyNow = "coworking-module--applyNow--1Zz1u";
export var sharing = "coworking-module--sharing--BU9qz";
export var mediaControls = "coworking-module--mediaControls--28Ter";
export var toggle = "coworking-module--toggle--26FFA";
export var active = "coworking-module--active--3Y4Qq";
export var noTour = "coworking-module--noTour--12U78";
export var repeatContent = "coworking-module--repeatContent--1FQtk";
export var fadedPatternBoxTopWrapper = "coworking-module--fadedPatternBoxTopWrapper--msGtb";
export var fadedPatternBoxTop = "coworking-module--fadedPatternBoxTop--1-4eU";
export var fadedPatternBox = "coworking-module--fadedPatternBox--2FSe0";
export var bottomPattern = "coworking-module--bottomPattern--3VrGU";
export var lined = "coworking-module--lined--3PkD9";
export var dark = "coworking-module--dark--3lmMo";
export var topLineHeader = "coworking-module--topLineHeader--21fL7";
export var hero = "coworking-module--hero--1AN1I";