import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { navigate } from 'gatsby';
import Layout from '@envy/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '@components/seo';
import Amenities from '@envy/Amenities';
import TheNeighborhood from '@envy/TheNeighborhood';
import News from '@envy/News';
import ContactForm from '@envy/ContactForm';
import Footer from '@envy/Footer';
import { NextButton, BackButton } from '@envy/ArrowButtons';
import favicon from '@images/envy/favicon.png';
import ShareIcon from '@images/envy/UnitPage/share-icon.svg';
import LogoTiny from '@images/envy/logo-tiny.svg';
import * as styles from './coworking.module.scss';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
} from 'react-share';

const isBrowser = typeof window !== 'undefined';
const win = (typeof window !== 'undefined') ? window : { location: { hash: '' }};
const doc = (typeof document !== 'undefined') ? document : { URL: ''};

const RequestInfoButton = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (win.location.hash === '#contact') {
      dispatch({ type: 'SET_CONTACT_COWORKING' });
    }
  }, [doc.URL]);
  return (
    <a
      id="button-apply"
      href='#contact'
      className={styles.applyNow}
      rel="noreferrer"
      onClick={() => dispatch({ type: 'SET_CONTACT_COWORKING' })}
    >
      REQUEST INFO
    </a>
  );
};

function CoworkingPage() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageCount = 1;
  const handleNext = () => setCurrentIndex(currentIndex + 1);
  const handleBack = () => setCurrentIndex(currentIndex - 1);

  const calculateLeft = () => {
    if (!isBrowser) return;

    return window.innerWidth * currentIndex;
  };

  const shareUrl = `https://bygomes.com/property/envy/coworking/`;
  return (
    <Layout>
      <Seo
        title={`Coworking | Envy by Gomes`}
        icon={favicon}
        description="This multi-purpose space was conscientiously developed for results."
      />
      <div className={styles.bannerCarousel}>
        {currentIndex < imageCount - 1 && (
          <NextButton
            onClick={handleNext}
            className={styles.nextButton}
            page={currentIndex}
            pageCount={imageCount}
          />
        )}
        {currentIndex > 0 && (
          <BackButton
            onClick={handleBack}
            className={styles.backButton}
            page={currentIndex}
            pageCount={imageCount}
          />
        )}
        <div
          className={styles.carousel}
          style={{ left: `-${calculateLeft()}px` }}
        >
          <div
            key="banner-image"
            className={classNames(styles.image, styles.hero)}
          />
        </div>
        {imageCount > 1 && (
          <div className={styles.mobileCounter}>
            <div className={styles.inner}>
              <span>{currentIndex + 1}</span>
              <span>{imageCount}</span>
            </div>
          </div>
        )}
      </div>
      <div className={styles.fadedPatternBoxTopWrapper}>
        <div className={styles.fadedPatternBoxTop} />
      </div>
      <div className={styles.container}>
        <div className={styles.sideLine}>
          <span>COWORKING AT ENVY</span>
          <div className={styles.line} />
          <LogoTiny />
        </div>
        <div className={styles.unitDetails}>
          <div className={styles.detailsContainer}>
            <h2>The Envy of Coworking</h2>
            <p>
              This multi-purpose space was conscientiously developed for results
              with amenities like; access to our Zen Garden for brainstorming,
              conference rooms set up for video conferencing, kitchenette and
              free refreshments for motivation, club room with shuffleboard for
              work breaks, a private events room, and more.
            </p>
            <h3>ROOM AMENITIES</h3>
            <ul>
              <li>Club room with shuffleboard</li>
              <li>Kitchenette with microwave / mini fridge.</li>
              <li>Free coffee and snacks offered daily</li>
              <li>Reservable Private Events Room</li>
              <li>
                Conference rooms with prebuilt in cameras for video conferencing
              </li>
              <li>Cafe</li>
              <li>Bespoke Zen Garden</li>
              <li>Bar</li>
              <li>Bespoke sports room</li>
              <li>NYC rooftop views</li>
            </ul>
          </div>
          <div className={styles.mediaContainer}>
            <StaticImage
              src="../../../images/envy/coworking.png"
              placeholder="None"
            />
          </div>
        </div>
        <div className={styles.controls}>
          <div className={styles.detailsControls}>
            <div className={styles.priceContainer}>
              <span>STARTING AT</span>
              <div>
                <span>$</span>
                200
                <span>
                  /MONTH<sup>*</sup>
                </span>
              </div>
            </div>
            <RequestInfoButton />
            <Tippy
              content={
                <div className={styles.shareContainer}>
                  <EmailShareButton url={shareUrl}>
                    <EmailIcon size={48} round />
                  </EmailShareButton>
                  <FacebookShareButton url={shareUrl}>
                    <FacebookIcon size={48} round />
                  </FacebookShareButton>
                  <TwitterShareButton url={shareUrl}>
                    <TwitterIcon size={48} round />
                  </TwitterShareButton>
                </div>
              }
              interactive
              theme="light"
              trigger="click"
            >
              <div className={styles.sharing}>
                <ShareIcon />
                SHARE
              </div>
            </Tippy>
          </div>
          <div className={styles.mediaControls}></div>
        </div>
      </div>
      <div className={styles.repeatContent}>
        <Amenities />
        <TheNeighborhood />
        <div className={classNames(styles.fadedPatternBox)} />
        <div className={classNames(styles.lined)} />
      </div>
      <div className={classNames(styles.dark, styles.bottomPattern)}>
        <News />
        <ContactForm />
        <div className={styles.repeatContent} style={{ padding: 0 }}>
          <Footer style={{ marginTop: '104px' }} />
        </div>
        <div className={classNames(styles.sidelines)} />
      </div>
    </Layout>
  );
}

export default CoworkingPage;
